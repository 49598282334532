import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_ADEPT_DATA } from 'Consts/routes';
import { 
    API_RESOURCE_USERS, 
} from 'Consts/apiResources';
import {
    USER_ROLE_ADEPT,
    USER_ROLE_TRAINER,
} from 'Consts/userRoles';
import { ENDPOINT_ADMIN_FILE_PRESIGN } from 'Consts/api';
import { 
    TYPES,
    TYPE_NO_PHOTO,
    TYPE_SINGLE_PHOTO,
    TYPE_TWO_PHOTOS,
} from 'Consts/userEffects';

import { fromSelectObject } from 'Utils/object';
import { withVariables } from 'Utils/string';
import { getFullName } from 'Utils/user';
import { getTypeLabel } from 'Utils/userEffect';

import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layout/panel/ElementEditor';
import { TAB_USER_EFFECTS } from 'Components/pages/admin/AdeptData/component';

export default class AdminUserEffectEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            update: PropTypes.func.isRequired,
            create: PropTypes.func.isRequired,
            remove: PropTypes.func.isRequired,
            listUsers: PropTypes.func.isRequired,
            presignFile: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
    };

    state = {
        formState: {},
    };

    componentDidMount = () => {
        const { data } = this.props;

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.dataToFormState(data || {}),              
            },
        }));
    }

    dataToFormState = data => {
        return {
            ...data,
            userId: data.user
                ? { value: data.user.id, label: getFullName(data.user).label }
                : null,
            leadId: data.lead
                ? { value: data.lead.id, label: getFullName(data.lead).label }
                : null,
            type: data.type
                ? { value: data.type, label: getTypeLabel(data).label }
                : null,
        };
    }

    formStateToData = formState => {
        return {
            ...formState,
            enabled: formState.enabled || false,
            promoted: formState.promoted || false,
            userId: fromSelectObject(formState.userId),
            leadId: fromSelectObject(formState.leadId),
            photoId: formState.photo ? formState.photo.id : undefined,
            photoBeforeId: formState.photoBefore ? formState.photoBefore.id : undefined,
            photoAfterId: formState.photoAfter ? formState.photoAfter.id : undefined,
            photoInfoId: formState.photoInfo ? formState.photoInfo.id : undefined,
            photoDetailsId: formState.photoDetails ? formState.photoDetails.id : undefined,
            photoCommentId: formState.photoComment ? formState.photoComment.id : undefined,
            type: fromSelectObject(formState.type),
        };
    }

    onSubmit = formState => {
        const { data } = this.props;

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onUpdate = formState => {
        const { actions, data } = this.props;

        return actions.update({
            ...this.formStateToData(formState),
            id: data.id,
        })
            .catch(error => {
                Object.keys(error.payload.validationErrors).map(fieldName => {
                    error.payload.validationErrors[fieldName].map(errorMessage => {
                        toast.error(`${errorMessage}`);
                    });
                });

                throw error;
            });
    }

    onCreate = formState => {
        const { actions, history } = this.props;

        return actions.create({
            ...this.formStateToData(formState),
        }).then(() => {
            history.push(
                withVariables(
                    ADMIN_ADEPT_DATA.path, 
                    { }, 
                    { tab: TAB_USER_EFFECTS }
                )
            );
        })
            .catch(error => {
                Object.keys(error.payload.validationErrors).map(fieldName => {
                    error.payload.validationErrors[fieldName].map(errorMessage => {
                        toast.error(`${errorMessage}`);
                    });
                });

                throw error;
            });
    }

    render() {
        const { location, history, data, actions } = this.props;
        const { formState } = this.state;

        return (
            <StyledComponent
                className="admin-user-effect-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    controls={[{
                        visible: Boolean(data),
                        title: 'Usuń efekt',
                        subtitle: 'Efekt zostanie usunięty',
                        buttonProps: {
                            onClick: () => actions.remove({ id: data && data.id }).then(() => {
                                return history.push(
                                    withVariables(
                                        ADMIN_ADEPT_DATA.path, 
                                        {}, 
                                        { tab: TAB_USER_EFFECTS }
                                    )
                                );
                            }),
                            children: 'Usuń',
                        },
                    }]}
                    helpImages={[{
                        visible: Boolean(formState.type?.value === TYPE_NO_PHOTO),
                        title: 'Lista efektów - tryb bez zdjęć',
                        subtitle: 'Zdjęcie w tle na każdym efekcie bez zdjęć jest takie same',
                        imageSrc: require('Theme/images/placeholders/user-effect-no-photo.png'),
                    }, {
                        visible: Boolean(formState.type?.value === TYPE_SINGLE_PHOTO),
                        title: 'Lista efektów - tryb z jednym zdjęciem',
                        subtitle: 'Zdjęcie w tle ustawiane w polu o nazwie \'Zdjęcie\'',
                        imageSrc: require('Theme/images/placeholders/user-effect-single-photo.png'),
                    }, {
                        visible: Boolean(formState.type?.value === TYPE_TWO_PHOTOS),
                        title: 'Lista efektów - tryb z dwoma zdjęciami - przed i po',
                        subtitle: 'Zdjęcia w tle to zdjęcia ustawiane w polach \'Zdjęcie przed\' oraz \'Zdjęcie po\'',
                        imageSrc: require('Theme/images/placeholders/user-effect-two-photos.png'),
                    }, {
                        visible: true,
                        title: 'Widok pojedynczego efektu - sekcja pierwsza',
                        subtitle: 'Zdjęcie do tej sekcji jest ustawiane w polu o nazwie \'Zdjęcie do informacji\'. Imię i nazwisko adepta jest na czarno',
                        imageSrc: require('Theme/images/placeholders/user-effect-info.png'),
                    }, {
                        visible: true,
                        title: 'Widok pojedynczego efektu - sekcja druga',
                        subtitle: 'Zdjęcie do tej sekcji jest ustawiane w polu o nazwie \'Zdjęcie do szczegółów\'',
                        imageSrc: require('Theme/images/placeholders/user-effect-details.png'),
                    }, {
                        visible: true,
                        title: 'Widok pojedynczego efektu - sekcja trzecia',
                        subtitle: 'Zdjęcie do tej sekcji jest ustawiane w polu o nazwie \'Zdjęcie do komentarza\'',
                        imageSrc: require('Theme/images/placeholders/user-effect-comment.png'),
                    }]}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'select',
                            name: 'userId',
                            label: 'Adept',
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listUsers({
                                    search: query,
                                    role: USER_ROLE_ADEPT
                                }),
                                onChange: value => this.setState(prevState => ({
                                    formState: {
                                        ...prevState.formState,
                                        userId: fromSelectObject(value)?.value,
                                    },
                                })),
                                onMapResponse: response => response.payload[API_RESOURCE_USERS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getFullName(element).label,
                                }),
                            },
                        }, {
                            type: 'select',
                            name: 'leadId',
                            label: 'Trener',
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listUsers({
                                    search: query,
                                    role: USER_ROLE_TRAINER
                                }),
                                onChange: value => this.setState(prevState => ({
                                    formState: {
                                        ...prevState.formState,
                                        leadId: fromSelectObject(value)?.value,
                                    },
                                })),
                                onMapResponse: response => response.payload[API_RESOURCE_USERS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getFullName(element).label,
                                }),
                            },
                        }, {
                            type: 'select',
                            name: 'type',
                            label: 'Typ',
                            options: TYPES.map(type => ({
                                value: type.key,
                                label: type.label,
                            })),
                            required: true,
                        }, {
                            type: 'input',
                            name: 'title',
                            label: 'Tytuł',
                            required: true,
                        }, {
                            type: 'input',
                            name: 'effect',
                            label: 'Osiągnięty efekt',
                            required: true,
                        }, {
                            type: 'input',
                            name: 'effectHeadline',
                            label: 'Nagłówek (ważne przy efekcie bez zdjęć)',
                        }, {
                            type: 'input',
                            name: 'effectSubheadline',
                            label: 'Podtytuł (ważne przy efekcie bez zdjęć)',
                        }, {
                            type: 'input',
                            name: 'effectDetailed',
                            label: 'Szczegóły efektu',
                        }, {
                            type: 'textarea',
                            name: 'text',
                            label: 'Tekst',
                            required: true,
                        }, {
                            type: 'textarea',
                            name: 'comment',
                            label: 'Komentarz adepta',
                        }, {
                            type: 'input',
                            name: 'commentAdditional',
                            label: 'Dodatkowa informacja do komentarza'
                        }, {
                            type: 'input',
                            name: 'weightLoss',
                            label: 'Różnica w wadze',
                        }, {
                            type: 'input',
                            name: 'metabolicAge',
                            label: 'Różnica w wieku metabolicznym',
                        }, {
                            type: 'input',
                            name: 'beltLoss',
                            label: 'Różnica w pasie',
                        }, {
                            type: 'input',
                            name: 'fatTissueLoss',
                            label: 'Różnica w tkance tłuszczowej'
                        }, {
                            type: 'input',
                            name: 'order',
                            label: 'Kolejność wyświetlania na liście efektów',
                            inputProps: {
                                type: 'number',
                            },
                        }, {
                            type: 'switch',
                            name: 'enabled',
                            label: 'Włączony/wyłączony',
                        }, {
                            type: 'switch',
                            name: 'promoted',
                            label: 'Widoczność na stronie głównej',
                        }, {
                            type: 's3FileUpload',
                            name: 'photo',
                            label: 'Zdjęcie',
                            isVisible: formState.type?.value === TYPE_SINGLE_PHOTO,
                            inputProps: {
                                action: actions.presignFile,
                                s3Config: {
                                    presignPath: ENDPOINT_ADMIN_FILE_PRESIGN
                                        .replace('{type}', 'userEffect'),
                                },
                            },
                        }, {
                            type: 's3FileUpload',
                            name: 'photoBefore',
                            label: 'Zdjęcie przed',
                            isVisible: formState.type?.value === TYPE_TWO_PHOTOS,
                            inputProps: {
                                action: actions.presignFile,
                                s3Config: {
                                    presignPath: ENDPOINT_ADMIN_FILE_PRESIGN
                                        .replace('{type}', 'userEffect'),
                                },
                            },
                        }, {
                            type: 's3FileUpload',
                            name: 'photoAfter',
                            label: 'Zdjęcie po',
                            isVisible: formState.type?.value === TYPE_TWO_PHOTOS,
                            inputProps: {
                                action: actions.presignFile,
                                s3Config: {
                                    presignPath: ENDPOINT_ADMIN_FILE_PRESIGN
                                        .replace('{type}', 'userEffect'),
                                },
                            },
                        }, {
                            type: 's3FileUpload',
                            name: 'photoInfo',
                            label: 'Zdjęcie do informacji nt. efektu',
                            isVisible: true,
                            required: true,
                            inputProps: {
                                action: actions.presignFile,
                                s3Config: {
                                    presignPath: ENDPOINT_ADMIN_FILE_PRESIGN
                                        .replace('{type}', 'userEffect'),
                                },
                            },
                        }, {
                            type: 's3FileUpload',
                            name: 'photoDetails',
                            label: 'Zdjęcie do szczegółów efektu',
                            isVisible: true,
                            required: true,
                            inputProps: {
                                action: actions.presignFile,
                                s3Config: {
                                    presignPath: ENDPOINT_ADMIN_FILE_PRESIGN
                                        .replace('{type}', 'userEffect'),
                                },
                            },
                        }, {
                            type: 's3FileUpload',
                            name: 'photoComment',
                            label: 'Zdjęcie do komentarza adepta',
                            isVisible: true,
                            required: true,
                            inputProps: {
                                action: actions.presignFile,
                                s3Config: {
                                    presignPath: ENDPOINT_ADMIN_FILE_PRESIGN
                                        .replace('{type}', 'userEffect'),
                                },
                            },
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}