export const TYPE_NO_PHOTO = 'noPhoto';
export const TYPE_SINGLE_PHOTO = 'singlePhoto';
export const TYPE_TWO_PHOTOS = 'twoPhotos';
export const TYPES = [
    {
        key: TYPE_NO_PHOTO,
        label: 'Bez zdjęć',
    },
    {
        key: TYPE_SINGLE_PHOTO,
        label: 'Jedno zdjęcie',
    },
    {
        key: TYPE_TWO_PHOTOS,
        label: 'Dwa zdjęcia - przed oraz po',
    }
];