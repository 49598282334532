import { TYPES } from 'Consts/userEffects';

export const getTypeLabel = userEffect => {
    if(!userEffect) {
        return { found: false, label: 'Nieznany' };
    }

    const { type } = userEffect;
    const option = TYPES.find(effectType => effectType.key == type);
    if (!option) {
        return { found: false, label: 'Nieznany' };
    }

    return { found: true, label: option.label };
};
